.Home-layout {
    display: block;
    text-align: center;
    width: 100%;
}

.Home-card {
    background-color: #e6f1e7;
    padding: 30px;
    padding-top: 0;
    margin: 15px;
    border-radius: 5px;
    box-shadow: 2px 2px 4px rgb(0 0 0 / 12%);
    display: inline-block;
    text-align: left;
}

.Home-h1 {
    color: #419245;
    font-size: 2.2em;
}
/*
.Home-logout {
    background-color: #6e757c;
    border-color: #6e757c;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    color: #fff;
    display: inline-block;
    font-weight: 400;
    font-size: 1rem;
    font-family: inherit;
    line-height: 1.5;
    margin-top: 15px;
    padding: 0.375rem 0.75rem;
    text-align: center;
    user-select: none;
    vertical-align: middle;
}

.Home-logout:hover {
    cursor: pointer;
    background-color: #5b6267;
}

.Home-logout:active {
    outline: 5px solid #c1c4c7;
}
*/
.Home-return_homebase {
    background-color: #67a86d;
    border-color: #67a86d;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    color: #fff;
    display: inline-block;
    font-weight: 400;
    font-size: 1rem;
    font-family: inherit;
    line-height: 1.5;
    margin-top: 15px;
    padding: 0.375rem 0.75rem;
    text-align: left;
    user-select: none;
    vertical-align: middle;
}

.Home-return_homebase:hover {
    cursor: pointer;
    background-color: #419249;
    border-color: #419249;
}

.Home-return_homebase:active{
    outline: 5px solid #a9ddae;
}

@media screen and (max-width: 959px) {
	/* 959px以下に適用されるCSS（タブレット用） */
}

@media screen and (max-width: 480px) {
	/* 480px以下に適用されるCSS（スマホ用） */
    .Home-return_homebase {
        width: 100%;
        text-align: center;
    }
}