.Point-operation-layout {
    text-align: center;
    width:auto;
}

.Point-operation-return_button {
    text-decoration: none;
}

.Point-operation-card_button ,.Point-operation-return_button {
    background-color: #67a86d;
    border-color: #67a86d;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    color: #fff;
    display: inline-block;
    font-weight: 400;
    font-size: 1rem;
    font-family: inherit;
    line-height: 1.5;
    margin-top: 15px;
    margin-left: 0;
    padding: 0.375rem 0.75rem;
    text-align: left;
    user-select: none;
    vertical-align: middle;
}

.Point-operation-card_button:hover , .Point-operation-return_button:hover {
    cursor: pointer;
    background-color: #419249;
    border-color: #419249;
}

.Point-operation-card_button:active , .Point-operation-return_button:active {
    outline: 5px solid #a9ddae;
}

.Point-operation-card_button {
    background-color: #f9a251;
    border-color: #f9a251;
    font-weight:600;
}

.Point-operation-card_button:hover {
    background-color: #fd9535;
    border-color: #fd9535;
}

.Point-operation-card_button:active {
    outline: 5px solid #f9cca3;
}

.Point-operation-card {
    background-color: #e6f1e7;
    padding: 30px;
    padding-top: 0;
    margin: 15px;
    border-radius: 5px;
    box-shadow: 2px 2px 4px rgb(0 0 0 / 12%);
    display: inline-block;
    text-align: left;
    max-width: 53em;
}

h1.Point-operation-h1 {
    color: #419245;
    font-size: 2.2em;
}

table.Point-operation-card_table {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed
}
 
.Point-operation-card_table th, .Point-operation-card_table td {
    padding: 0.5em;
    text-align: left;
    overflow-wrap: break-word; 
    word-wrap: break-word;
}
 
.Point-operation-card_table th {
    background-color: #419245;
    border-right: 1px solid #e6f1e7;
    color: #fff;
    width: 10em;
}
 
.Point-operation-card_table td {
    background-color: #61a157;
    color: #fff;
}
 
.Point-operation-card_table tr {
    border-bottom: 1px solid #e6f1e7;
}

.Point-operation-ly {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.Point-operation-left {
    margin-right: 10px;
    width: 20%;
    order:0;
}

.Point-operation-center {
    text-align: center;
    width: 25%;
    order:1;
}

.Point-operation-right {
    margin-left: 10px;
    text-align: right;
    width: 55%;
    order:2;
}

.Point-operation-card_button-icon {
    font-size: 1.2em;
    margin-left: 5px;
}

@media screen and (max-width: 480px) {
	/* 480px以下に適用されるCSS（スマホ用） */
    .Point-operation-card_table th {
        width: 7em;
    }

    .Point-operation-ly {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    
    .Point-operation-left {
        order:2;
        width: 100%;
    }

    .Point-operation-center {
        order:1;
        text-align: left;
        width: 100%;
    }
    
    .Point-operation-right {
        margin-left: 0;
        order:0;
        text-align: left;
        width: 100%;
    }

    .Point-operation-card_button {
        width: 100%;
        text-align: center;
    }

    .Point-operation-return_button {
        width: 100%;
        text-align: center;
        -webkit-box-sizing: border-box;  /*webkit系*/
        -moz-box-sizing: border-box;  /*Firefox*/
        box-sizing: border-box;
    }
}