.Point-operations-list {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    width: 100%;
}

.Point-operations-list-item {
    font-feature-settings: "palt";
}

.Point-operations-list-item:first-child{
    margin-right: auto;
}

.Point-operations-list_circle {
    color: #419245;
    margin-left: 5px;
    margin-right: 5px;
}

.Point-operations-list-button {
    background-color: #67a86d;
    border-color: #67a86d;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    color: #fff;
    display: inline-block;
    font-weight: 400;
    font-size: 1rem;
    font-family: inherit;
    line-height: 1.5;
    padding: 0.375rem 0.75rem;
    user-select: none;
    vertical-align: middle;
    text-align: left;
    margin-top:-0.55em;
}

.Point-operations-list-button:hover {
    cursor: pointer;
    background-color: #419249;
    border-color: #419249;
}

.Point-operations-list-button:active{
    outline: 5px solid #a9ddae;
}

.Point-operations-list-arrow{
    position: relative;
    display: inline-block;
    padding: 0 0 0 15px;
    margin: 0;
    color: #000;
    vertical-align: middle;
    text-decoration: none;
    font-size: 15px;
}

.Point-operations-list-arrow::before,
.Point-operations-list-arrow::after{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    content: "";
    vertical-align: middle;
}

.Point-operations-list-detail {
    color: #419245;
    font-weight: bold;
    margin-top: -3px;
    margin-left: 10px;
    margin-right: 5px;
}

.Point-operations-list-detail:hover{
    border-bottom: 1px solid #419245;
    line-height: 1.7em;
}

.Point-operations-list-detail::before{
    left: 3px;
    width: 4px;
    height: 4px;
    border-top: 2px solid #419245;
    border-right: 2px solid #419245;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

@media screen and (max-width: 480px) {
	/* 480px以下に適用されるCSS（スマホ用） */
    .Point-operations-list {
        flex-direction: column;
        justify-content:left;
    }

    .Point-operations-list_circle {
        display: none;
    }

    .Point-operations-list-button {
        margin-top: 10px;
        margin-bottom: 10px;
        text-align: center;
        width: 100%;
    }

    .Point-operations-list-detail {
        border-bottom: 1px solid #e8f1e8;
        margin-top: 10px;
        margin-left: 0;
        line-height: 1.7em;
    }

    .Point-operations-list-detail:hover {
        line-height: 1.7em;
    }
}